import React from 'react';
import styled from 'styled-components';
import { Header } from '@/components/header';
import { VSpace } from '@/components/spacing';
import { MobileDashboardHeader } from '@/features/dashboard/shared/mobile-header';
import { breakpoints } from '@/components/responsive';

export function HeaderLayout({
  children,
  hideBackToLive,
}: {
  children: any;
  hideBackToLive?: true;
}) {
  return (
    <Wrapper>
      <Header className="only-desktop" hideBackToLive={hideBackToLive} />
      <MobileDashboardHeader />
      <Body>
        {children}
        <VSpace value={4} />
      </Body>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 7rem 1fr;
`;

const Body = styled.div`
  background: rgb(248, 249, 251);
  padding: 2rem 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: flex-start;
  justify-items: center;

  ${breakpoints.down(`md`)} {
    padding: 2rem 0;
  }
`;
